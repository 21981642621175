* {
  font-family: Poppins, sans-serif !important;
}

.reset-page h1 {
  font-size: 30px;
  color: black;
  font-weight: 700;
  margin-bottom: 24px;
}

.MuiOutlinedInput-root {
  background: #fff;
  border: none;
  border-radius: 16px !important;
}

.MuiOutlinedInput-input {
  padding: 15px 15px !important;
}

.MuiButton-containedgreen {
  background: #00b052 !important;
  border: 1.6px solid #00b052 !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  min-height: 65px !important;
  text-transform: none !important;
  transition: 0.5s;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 700 !important;
  color: #000000;
}

.Mui-focused {
  border-color: #1c59f0;
}

.error-border {
  border-color: #ff0000;
}

.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button-shadow {
  box-shadow: 0 2px 6px #333;
}

.swal2-title.Swal-title-class {
  font-size: 20px;
  color: #000000;
}
.main-container-header {
  box-shadow: 0px 0px 6px 1px #a1a1a181;
}

.updatebtn {
  min-width: 100px !important;
  font-weight: 600 !important;
  background: #00b052 !important;
  color: white !important;
  border-color: #00b052 !important;
}

.side-menu-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.side-header.active li {
  background-color: #00b052;
}

.side-header1.active li p,
.side-header1.active li svg {
  color: #00b052;
}

.side-menu-box.small-side-menu {
  translate: -300px;
}

.capitalize {
  text-transform: capitalize !important;
}

.bulkuploadbtn {
  padding: 0 !important;
  margin: 0 12px !important;
}
.bulkuploadbtn label {
  padding: 6px 16px;
}

.no-spin-buttons input[type="number"] {
  -moz-appearance: textfield;
}

.no-spin-buttons input[type="number"]::-webkit-outer-spin-button,
.no-spin-buttons input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  border-color: none;
  outline: none !important;
}

.select-style {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10L12 15L17 10" stroke="%23000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.side-menu-box.small-side-menu ul li {
  padding: 12px 20px !important;
}

.side-menu-box.small-side-menu ul li svg {
  margin: 0;
}

.button-shadow {
  box-shadow: 0 2px 6px #333;
}

.button-style {
  background: #00b052 !important;
  font-weight: 700 !important;

  &:hover {
    background: #e5fff1 !important;
    color: #00b052;
  }
}

.small-side-menu + .main-content {
  padding-left: 2.5rem !important;
}

@media (max-width: 500px) {
  .small-device-width {
    width: 350px;
  }
}

@media (max-width: 700px) {
  .small-device-width {
    width: 450px;
  }

  .main-content {
    padding-left: 40px !important;
  }

  .side-menu-box {
    translate: -300px;
  }

  .side-menu-box.small-side-menu {
    translate: 0px;
  }

  .top-header {
    padding-left: 1.75rem;
  }

  .top-header.small-side-menu {
    padding-left: 290px;
  }
}

.loading-img {
  max-width: 600px;
  width: calc(100vw - 100px);
  margin: 0 auto;
}

.loading-img img {
  width: 100%;
  transform-origin: center top;
  animation: scale 1500ms ease-in-out infinite;
}

.MuiPaper-rounded.MuiDialog-paperWidthSm {
  border-radius: 16px !important;
  color: #65717b !important;
  width: 530px;
  z-index: 0;
  // min-height: 400px;
}

.css-1nmdiq5-menu {
  z-index: 999999 !important;
  background-color: #ff0000 !important;
}
// .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root{height: 300px;}

/* Hide scrollbar for Chrome, Safari and Opera */
.css-1nmdiq5-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.css-1nmdiq5-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.css-13cymwt-control {
  border-color: #0000003b !important;
}

//--------- select menu css ------------

.custom-select-container {
  background-color: #fff;
  color: #000;
  border: 1px solid #e2e8f0 !important;
  border-radius: 5px;
}

.custom-select-menu,
.custom-select-option,
.custom-select-single-value {
  background-color: #fff;
  color: #000;
}

.custom-select-option:hover {
  background-color: #f0f0f0;
  color: #000;
}

.custom-select-container .react-select__control,
.custom-select-container .react-select__menu,
.custom-select-container .react-select__option,
.custom-select-container .react-select__option--is-focused,
.custom-select-container .react-select__single-value {
  background-color: #fff !important;
  color: #000 !important;
  border: none;
}

.css-13cymwt-control .css-1u9des2-indicatorSeparator {
  background-color: #f1f5f9 !important;
}
